.h-reset-list{
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

body {
	margin: 0;
	padding: 0;
}

*{
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

button{
	border: none;
	border-radius: 0;
	outline: none;
	cursor: pointer;
}

p {
	margin: 0;
	font-family: FuturaPT;
}