.form-control {
	&::placeholder{
		transition: opacity 0.3s ease;
		opacity: 1;
	}
	&:hover {
	}
	&[readonly] {
		&:hover {}
		&:focus {}
		&:active {}
	}
	&:focus {
		// box-shadow: none;

		&::placeholder{
			opacity: 0;
		}
	}
	&:active {
		// box-shadow: none;
	}
	&.error {
		border-color: #ff0000 !important;
		background-color: #ffdede;
	}

	&[contenteditable="true"],
	// textarea& {}
	&[contenteditable="true"] {}
	// textarea& {
	// 	min-height: 120px;
	// 	max-height: 500px;
	// 	resize: vertical;
	// }
	// input& {
	// 	height: 34px;
	// }
	&:disabled,
	&[disabled],
	&.disabled {
		.form-control {
			pointer-events: none;
			cursor: default;
			opacity: 0.5;
		}
	}
}
.form-group {
	// margin-bottom: 15px;
	&:disabled,
	&[disabled],
	&.disabled {
		.form-control {
			pointer-events: none;
			cursor: default;
			opacity: 0.5;
		}
	}
}
.input-wrap,
.select-wrap {
	position: relative;
}
.select-wrap {}
.input-wrap {
	& > .ico {}
}
.bootstrap-select {
	&:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
		width: 100%;
	}
	&.btn-group.open .dropdown-toggle {
		.caret{
		}
		.bs-caret {
		}
	}
	& > .dropdown-toggle {
		&:hover {
			.bs-caret {}
		}
		&:focus {
		}
		&:active {}

		&.dropdown-toggle,
		&.bs-placeholder {
			&,
			&:hover,
			&:focus,
			&:active {
				outline: none!important;
			}
		}

		span {}
	}
	.dropdown-menu {
		&.open {}
		ul.dropdown-menu {
			& > li {
				&.selected.active {
					& > a {}
				}
				& > a {}
			}
		}
	}
}
label {
	margin-bottom: 0;
	font-weight: 400;
	cursor: pointer;

	&.error {
		color: #ff0000;
	}
}
.hidden-input {
    visibility: hidden !important;
	position: absolute !important;
	z-index: -1 !important;
    opacity: 0 !important;
    height: 0 !important;
    width: 0 !important;
    left: 0 !important;
    top: 0 !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}
.field_clear_btn {
	transition: color 0.3s ease;
	margin-bottom: auto;
	position: absolute;
	margin-top: auto;
	display: block;
	color: #d8d8d8;
	height: 30px;
	width: 30px;
	bottom: 0;
	right: 0;
	top: 0;

	&:hover {
		border-color: #b61f27;
		color: #b61f27;
	}
	&:focus {}
	&:active {}
	&:before,
	&:after {
		background-color: currentColor;
		position: absolute;
		display: block;
		margin: auto;
		content: '';
		height: 2px;
		width: 16px;
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
}
.checkbox-label {
	position: relative;
	padding-left: 30px;

	&:disabled,
	&[disabled],
	&.disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;
	}

	.check-icon {
		border: solid 1px #979797;
		border-radius: 4px;
		position: absolute;
		display: block;
		height: 18px;
		width: 18px;
		left: 0;
		top: 2px;

		&:before {
			transform: rotate(-52.5deg) skew(-15deg);
			transition: opacity 0.3s ease;
			border-bottom: solid 3px #b61f27;
			border-left: solid 3px #b61f27;
			position: absolute;
			display: block;
			margin: auto;
			content: '';
			height: 9px;
			width: 15px;
			opacity: 0;
			top: -10px;
			left: 5px;
			bottom: 0;
			right: 0;
		}
	}
	.check-value {
		transition: color 0.3s ease;
		color: #323232;
	}
	&.label_grey {
		.check-value {
			color: #979797;
		}
	}
	input {
		&:checked {
			& ~ .check-icon {
				&:before {
					opacity: 1;
				}
			}
		}
		&:disabled {
			& ~ .check-icon {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
			& ~ .check-value {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
		}
	}
}
.radio-label {
	padding-left: 20px;
	position: relative;

	&:disabled,
	&[disabled],
	&.disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;
	}

	.check-icon{
		border: solid 1px #0171a6;
		border-radius: 50%;
		position: absolute;
		display: block;
		height: 13px;
		width: 13px;
		top: 4px;
		left: 0;

		&:before{
			transition: opacity 0.3s ease;
			background-color:  #0171a6;
			border-radius: 50%;
			position: absolute;
			display: block;
			margin: auto;
			content: '';
			height: 5px;
			width: 5px;
			opacity: 0;
			bottom: 0;
			right: 0;
			left: 0;
			top: 0;
		}
	}
	.check-value {
		transition: color 0.3s ease;
		color: #979797;
	}
	&.label_grey {
		.check-value {
			color: #979797;
		}
	}
	input {
		&:checked {
			& ~ .check-icon {
				&:before {
					opacity: 1;
				}
			}
		}
		&:disabled {
			& ~ .check-icon {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
			& ~ .check-value {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
		}
	}
}