.c-contacts {
	width: auto;
	min-width: 100vw;
	position: relative;
	@media screen and ( max-width: 1199px ) {
		padding: 90px;
		padding-bottom: 267px;
	}
	@media screen and ( max-width: 580px ) {
		padding: 24px;
		padding-bottom: 168px;
	 }
	.c-section__desc {
		height: auto;
		margin-bottom: -250px;
		margin-top: 200px;
		position: absolute;
		bottom: 120px;
		left: 90px;
		margin: 0;
		@media screen and ( max-width: 1919px ) {
			bottom: 50px;
		 }
		@media screen and ( max-width: 1199px ) {
			position: relative;
			left: auto;
			bottom: auto;
		}
		@media screen and ( max-width: 580px ) {
			margin-top: -50px;
		 }
	}
	.c-section__title h5 , .c-section__subtitle span {
		color: #fff;
	}
	&__inner {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@media screen and ( max-width: 1199px ) {
			flex-direction: column-reverse;
		}
	}
	&__body {
		color: #fff;
		margin-left: 120px;
		display: flex;
		@media screen and ( max-width: 1199px ) {
			margin-left: 0;
			flex-direction: column;
			margin-top: 90px;
		}
		@media screen and ( max-width: 580px ) {
			margin-top: 48px;
		 }
	}
	.col {
		margin-right: 335px;
		@media screen and ( max-width: 1199px ) {
			margin-left: 0;
			flex-direction: column;
			margin-bottom: 90px;
			margin-right: 0;
		}
		@media screen and ( max-width: 580px ) {
			margin-bottom: 18px;
		 }
	}
	&__city {
		h3 {
			font-family: GothamPro;
			font-size: 18px;
			line-height: 27px;
			text-transform: uppercase;
			color: #fff;
			font-weight: 500;
			@media screen and ( max-width: 1199px ) {
				font-size: 16px;
			}
		}
	}
	&__address {
		margin-top: 12px;
		span {
			font-family: FuturaPT;
			font-size: 18px;
			line-height: 27px;
			color: #fff;
		}
	}
	&__map {
		margin-top: 8px;
		a {
			display: flex;
			align-items: center;
			span {
				font-family: FuturaPT;
				font-size: 18px;
				line-height: 27px;
				color: #888888;
				margin-left: 10px;
			}
		}
	}
	&__phone-title {
		margin-top: 30px;
		margin-left: -11px;
		span {
			font-family: FuturaPT;
			font-size: 18px;
			line-height: 27px;
			color: #888888;
			margin-left: 10px;
		}
	}
	&__phone {
		margin-top: -2px;
		display: flex;
		flex-direction: column;
		a {
			font-family: FuturaPT;
			font-size: 18px;
			line-height: 27px;
			color: #fff;
			margin-left: -2px;
   	 margin-bottom: 2px;
		}
	}
	// .c-header {
	// 	@media screen and ( max-width: 580px ) {
	// 		display: none;
	// 	 }
	// }
}

.c-contacts__link {
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 116px;
	right: 146px;
	@media screen and ( max-width: 1199px ) {
		left: 90px;
		bottom: 100px;
	}
	@media screen and ( max-width: 580px ) {
		bottom: 60px;
		left: 24px;
	 }
	a {
		font-family: GothamPro;
		font-size: 18px;
		line-height: 27px;
		color: #fff;
		margin-bottom: 12px;
		@media screen and ( max-width: 580px ) {
			font-size: 16px;
		 }
	}
} 