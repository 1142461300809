.l-wrapper{
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	&__content {
		background-color: #000;
		height: 100vh;
		display: flex;
		overflow-x: scroll;
		overflow-y: hidden;
		position: relative;
		@media screen and ( max-width: 1199px ) {
			flex-direction: column;
			overflow-y: scroll;
			overflow-x: hidden;
			height: auto;
		 }
		&::-webkit-scrollbar {
			width: 0; 
			height: 0;
		}
	}
}
.container {
	max-width: 1856px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
}

*:focus {
	outline: none;
}

.c-section {
	&__desc {
		display: flex;
		justify-content: flex-end;
		margin-bottom: -200px;
		flex-direction: column;
		margin-left: 78px;
		&--goods {
			margin-left: 78px;
			margin-bottom: -203px;
			position: absolute;
			bottom: 120px;
			left: 80px;
			margin: 0;
			.c-section__title {
				bottom: -16px;
				right: -11px;
			}
			.c-section__subtitle {
				margin-top: 11px;
			}
			@media screen and ( max-width: 1199px ) {
				margin-left: 0;
				margin-top: 90px;
				margin-bottom: 0;
				position: relative;
				bottom: auto;
				left: auto;
			}
			@media screen and ( max-width: 580px ) {
				margin-top: -50px;
			 }
		}
		&--contact {
			.c-section__title {
				bottom: -7px;
				right: 1px;
		}
		}
	}
	&__img {
		position: relative;
		display: inline-block;
		width: 125px;
		@media screen and ( max-width: 1199px ) {
			margin-left: -42px;
		}
		svg {
			animation: liquid 10s  0.01s linear infinite;
		}
	}
	&__title {
		position: absolute;
		bottom: 0px;
		right: -40px;
		h5 {
			font-family: FuturaPT;
			font-size: 21px;
			line-height: 32px;
			color: #101010;
			font-weight: 400;
			
		}
	}
	&__subtitle {
		display: block;
		max-width: 420px;
		margin-left: 42px;
		@media screen and ( max-width: 1199px ) {
			margin-left: 0;
		}
		span {
			font-family: GothamPro;
			font-size: 48px;
			line-height: 54px;
			color: #101010;
			font-weight: 500;
			text-transform: uppercase;
			@media screen and ( max-width: 1199px ) {
				font-size: 36px;
				line-height: 42px;
			}
			@media screen and ( max-width: 580px ) {
				font-size: 24px;
			 }

		}
	}
}


@keyframes liquid {
  0% {
		transform: rotate(0deg);
	}
  100% {
		transform: rotate(360deg);
	}
}

.loader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999999;
	background-color: #000;
	display: flex;
	justify-content: center;
	align-items: center;
	&__inner {
		width: 600px;
		height: 600px;
		position: relative;
		border-radius: 50%;
		z-index: 99;
		@media screen and ( max-width: 1919px ) {
			height: 444px;
			width: 444px;
		 }
		 @media screen and ( max-width: 1599px ) {
			height: 379px;
			width: 379px;
		 }
		 @media screen and ( max-width: 1365px ) {
			height: 444px;
			width: 444px;
		 }
		 @media screen and ( max-width: 1199px ) {
			width: 427px;
			height: 427px;
		}
		@media screen and ( max-width: 580px ) {
			width: 266px;
			height: 266px;
		 }
		.ab {
			display: flex;
			justify-content: center;
			align-items: center;
			transform: rotate(180deg);
			@media screen and ( max-width: 1919px ) {
				height: 444px !important;
				width: 444px !important;
			 }
			 @media screen and ( max-width: 1599px ) {
				height: 379px !important;
				width: 379px !important;
			 }
			 @media screen and ( max-width: 1365px ) {
				height: 444px !important;
				width: 444px !important;
			 }
			 @media screen and ( max-width: 1199px ) {
				width: 427px !important;
				height: 427px !important;
			}
			@media screen and ( max-width: 580px ) {
				width: 266px !important;
				height: 266px !important;
			 }
		}
		.cir {
			background: #000 !important;
			top: 0 !important;
			left: 0 !important;
			border: 2px solid rgba(255, 255, 255, 0.2);
			position: relative;
			@media screen and ( max-width: 1919px ) {
				height: 440px !important;
				width: 440px !important;
			 }
			 @media screen and ( max-width: 1599px ) {
				height: 375px !important;
				width: 375px !important;
			 }
			 @media screen and ( max-width: 1365px ) {
				height: 440px !important;
				width: 440px !important;
			 }
			 @media screen and ( max-width: 1199px ) {
				width: 422px !important;
				height: 422px !important;
			}
			@media screen and ( max-width: 580px ) {
				width: 264px !important;
				height: 264px !important;
			 }
			&:after {
				content: '';
				position: absolute;
				bottom: -7px;
				left: 50%;
				transform: translateX(-50%);
				display: inline-block;
				height: 9px;
				width: 9px;
				background-color: #fff;
				border-radius: 50%;
				
			}
		}
	}

	&__logo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 999;
		svg {
			animation: liquid 10s  0.01s linear infinite;
		}
		&-name {
			transform: translateX(-50%) translateY(-50%);
			top: 50%;
			left: 50%;
			position: absolute;
			span {
				font-family: GothamPro;
				font-size: 21px;
				line-height: 20px;
				text-transform: uppercase;
				color: #fff;
				font-weight: 900;
			}
		}
	}
}

.modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000;
	z-index: 99999999;
	opacity: 1;
	visibility: visible;
	transition: transform 1s ease;
	transform: translatex(-100vw);
	display: block !important;
	padding: 0 30px;
	overflow-y: scroll; 
	@media screen and ( max-width: 1199px ) {
		padding: 0 90px;
	}
	@media screen and ( max-width: 1199px ) {
		padding: 0 24px;
	}
	@media screen and ( max-width: 580px ) {
		transform: translatex(-200vw);
		padding: 0 24px;
		padding-bottom: 20px;
	 }
	&.active {
		transform: translatex(0);
	}
	&__inner {
		height: 100%;
	}
	&__form {
		z-index: 999999;
		padding-bottom: 20px;
	}
	&__top-line {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: -91px;
		@media screen and ( max-width: 580px ) {
			margin-top: 30px;
			flex-direction: column-reverse;
			align-items: flex-start;
			margin-top: 100px;
		 }
		.modal-title {
			position: relative;
			svg {
				animation: liquid 10s  0.01s linear infinite;
				position: absolute;
				top: -41px;
				left: -83px;
			}
			span {
				color: #fff;
				font-family: FuturaPT;
				font-size: 21px;
				line-height: 31px;
				@media screen and ( max-width: 1023px ) {
					font-size: 18px;
				}
			}
		}
	}
	&-contact {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		@media screen and ( max-width: 580px ) {
			align-items: flex-start;
			margin-bottom: 48px;
		 }
		&-phone {
			font-family: FuturaPT;
			font-size: 18px;
			line-height: 37px;
			color: white;
		}
		&-mail {
			font-family: GothamPro;
			font-size: 18px;
			line-height: 27px;
			color: white;
		}
	}
	&__form {
		width: 100%;
		margin-top: 10px;
		@media screen and ( max-width: 580px ) {
			margin-top: 50px;
		 }
		&-line {
			display: flex;
			justify-content: space-between;
			width: 100%;
			@media screen and ( max-width: 580px ) {
				flex-direction: column;
			 }
		}
		&-name,&-company,&-phone,&-email{
			width: 696px;
			margin-bottom: 38px;
			@media screen and ( max-width: 1599px ) {
				width: 47%;
			}
			@media screen and ( max-width: 1199px ) {
				width: 47%;
			}
			@media screen and ( max-width: 1023px ) {
				width: 47%; 
			}
			@media screen and ( max-width: 580px ) {
				width: 100%;
			 }
			input {
				background-color: transparent;
				border: none;
				border-bottom: 1px solid rgba(255, 255, 255, 0.3);
				width: 100%;
				padding-bottom: 10px;
				font-family: FuturaPT;
				font-size: 21px;
				line-height: 31px;
				color: #fff;
				&::placeholder {
					font-family: FuturaPT;
				font-size: 21px;
				line-height: 31px;
				color: rgba(255, 255, 255, 0.2);
				}
			}
		}
		&-question{
			width: 100%;
			margin-bottom: 75px;
			textarea {
				background-color: transparent;
				border: none;
				border-bottom: 1px solid rgba(255, 255, 255, 0.3);
				width: 100%;
				padding-bottom: 10px;
				font-family: FuturaPT;
				font-size: 21px;
				line-height: 31px;
				color: rgb(255, 255, 255);
				&::placeholder {
					font-family: FuturaPT;
					font-size: 21px;
					line-height: 31px;
					color: rgba(255, 255, 255, 0.2);
				}
			}
		}
		&-legacy {
			span {
				font-family: FuturaPT;
				font-size: 18px;
				line-height: 27px;
				color: #fff;
			}
			a {
				text-decoration: underline;
				color: #fff;
				&:hover {
					text-decoration: none;
				}
			}
		}
		&-submit {
			button {
				background: #FFFFFF;
				border-radius: 5px;
				font-family: GothamPro;
				font-size: 12px;
				line-height: 60px;
				color: #101010;
				text-transform: uppercase;
				text-align: center;
				padding: 0 44px;
				font-weight: 900;
				transition: all 0.3s ease;
				&:hover {
					background: darken($color: #ffffff, $amount: 20);
				}
				@media screen and ( max-width: 580px ) {
					width: 100%;
				 }
			}
			@media screen and ( max-width: 580px ) {
				margin-top: 48px;
			 }
		}
	}
	&__body {
		max-width: 1440px;
		width: 100%;
		margin: 0 auto;
		height: 103%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		@media screen and ( max-width: 580px ) {
			height: auto;
		 }
	}
}

.video {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
	pointer-events: none;
	min-height: 100vh;
	@media screen and ( max-width: 1199px ) {

	 }
}

.c-goods .c-header__inner {
	.c-header__logo {
		a {
			color: #101010;
		}
	}
	.c-header__contact-btn {
		border: 1px solid rgba(16, 16, 16, 0.28);
		color: #101010;
		transition: all 0.3s ease;
		&:hover {
			background: #101010;
			border: 1px solid #101010 !important;
			color: #fff !important;
		}
	}
	.c-header__menu-btn {
		&:after, &:before {
			content: '';
			background-color: #101010;
		}
	}
}
.c-fish .c-header__inner {
	.c-header__logo {
		a {
			color: #101010;
		}
	}
	.c-header__contact-btn {
		border: 1px solid rgba(16, 16, 16, 0.28);
		color: #101010;
	}
	.c-header__menu-btn {
		&:after, &:before {
			content: '';
			background-color: #101010;
		}
	}
}