button{
    // button reset
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
}
.btn{
    display: inline-block;
    height: 51px;
    border-radius: 11px;
    font-weight: 400;
    line-height: 48px;
    padding: 0 28px;
    border: 1px solid transparent;
    background-color: transparent;
    color: inherit;
    transition: all 300ms ease;
    &:hover{
        
        // border-color: @White40;
        // background-color: @White80;
        // color: @CapeCod;
    }
    &:focus,
    &:active{
        // border-color: @White40;
        // background-color: @Concrete;
        // color: @CapeCod;
    }
    // colors
    &_white{
    }
    // states
    // a&{
    //     text-decoration: none;
    // }
    &_icon{
    }
    // sizes
    &_md{
    }
    &_sm{
    }
    &_xs{
    }
}