.c-about {
	min-width: 100vw;
	position: relative;
	background-image: url(../images/about.jpg);
	@media screen and ( max-width: 1199px ) {
		padding: 90px;
	}
	@media screen and ( max-width: 580px ) {
		padding: 1px;
		overflow: hidden;
		position: relative;
		z-index: 3;
	 }
	&__inner {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__graph {
		z-index: 3;
		position: relative;
		display: flex;
		justify-content: center;
		 &-img {
			 height: 600px;
			 width: 600px;
			 position: relative;
			 @media screen and ( max-width: 1365px ) {
				height: 444px;
				width: 444px;
			 }
			 @media screen and ( max-width: 1199px ) {
				width: 300px;
				height: 300px;
				margin-left: auto;
			}
			@media screen and ( max-width: 580px ) {
				width: 100%;
				height: 100%;
				background-color: #101010;
			 }
		 }
		 &-txt {
			 position: absolute;
			 bottom: 100px;
			 left: 50%;
			 transform: translateX(-50%);
			 @media screen and ( max-width: 1199px ) {
				bottom: 40px;
				width: 175px;
				text-align: center;
			}
			
			 span {
				 font-family: GothamPro;
				 font-size: 12px;
				 line-height: 18px;
				 text-transform: uppercase;
				 color: #fff;
			 }
		 }
	}
	&__desc {
		margin-top: -13px;
    margin-left: 91px;
		max-width: 420px;
		z-index: 3;
		position: absolute;
		bottom: 120px;
		left: 90px;
		margin: 0;
		@media screen and ( max-width: 1599px ) {
			left: 60px;
			bottom: 90px;
			max-width: 240px;
			margin: 0;
		 }
		 @media screen and ( max-width: 1365px ) {
			bottom: 90px;
			left: 60px;
		 }
		 @media screen and ( max-width: 1199px ) {
			position: relative;
			bottom: auto;
			left: auto;
		}
		@media screen and ( max-width: 580px ) {
			padding: 35px 24px;
			position: relative;
			width: 100%;
			margin: 0 auto;
			max-width: 100%;
		}
		p {
			font-family: FuturaPT;
			font-size: 18px;
			line-height: 27px;
			color: #fff;
		}
	}
	// .c-header {
	// 	@media screen and ( max-width: 580px ) {
	// 		display: none;
	// 	 }
	// }
	.video {
		height: 100%;
	}
}