.c-goods {
	min-width: 3462px;
	width: 3462px;
	background-color: #fff;
	position: relative;
	@media screen and ( max-width: 1199px ) {
		padding: 0 90px;
		min-width: 100%;
		width: 100%;
		padding-bottom: 120px;
	}
	@media screen and ( max-width: 580px ) {
		padding: 24px;
	 }
	&__inner {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		 @media screen and ( max-width: 1199px ) {
			flex-direction: column;
			align-items: flex-start;
		}
	}
	&__items {
		display: flex;
		flex-wrap: nowrap;
		margin-left: 156px;
		display: flex;
		width: 100%;
		justify-content: center;
		margin: 0;
		padding-left: 600px;
		@media screen and ( max-width: 1199px ) {
			flex-direction: column;
			margin-left: 0;
			margin-top: 90px;
		}
		@media screen and ( max-width: 1199px ) {
			padding-left: 0;
		}
		@media screen and ( max-width: 580px ) {
			margin-top: 48px;
		 }
	}
	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-shrink: 0;
		&:not(:last-child) {
			margin-right: 240px;
			@media screen and ( max-width: 1199px ) {
				margin-right: 0;
			}
		}
		&--video {
			.c-goods__item-title {
				position: absolute;
				left: -84px;
				bottom: 48px;
				transform: rotate(-90deg);
				@media screen and ( max-width: 1199px ) {
					transform: rotate(0);
					bottom: -60px;
					left: 0;
				}
			}
			&-wrap {
				position: relative;
				width: 640px;
				height: 320px;
				@media screen and ( max-width: 1199px ) {
					width: 100%;
					height: 320px;
				}
				video {
					@media screen and ( max-width: 1199px ) {
						 height: 100%;
					}
				}
			}
		}
		&--bottom-img {
			flex-direction: column-reverse;
			justify-content: flex-end;
			position: relative;
			align-items: flex-start;
			@media screen and ( max-width: 1199px ) {
				flex-direction: column;
			}
			.c-goods__item-title {
				position: absolute;
				left: 0;
				top: -40px;
				@media screen and ( max-width: 1199px ) {
					top: auto;
					bottom: -60px;
					left: 0;
				}
			}
			&-wrap {
				margin-top: 116px;
				position: relative;
				width: 360px;
				height: 236px;
				@media screen and ( max-width: 1199px ) {
					margin-top: 90px;
				}
				@media screen and ( max-width: 580px ) {
					width: 100%;
				 }
			}

		}
		&--left-img {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			@media screen and ( max-width: 1199px ) {
				flex-direction: column;
				padding-top: 50px;
			}
			.c-goods__item-title {
				position: absolute;
				left: -77px;
				bottom: 36px;
				transform: rotate(-90deg);
				@media screen and ( max-width: 1199px ) {
					transform: rotate(0);
					left: 0;
					top: 0;
				}
			}
			&-wrap {
				position: relative;
				margin-right: 90px;
				height: 560px;
				width: 320px;
				@media screen and ( max-width: 1199px ) {
					width: 100%;
					height: auto;
					img {
						display: none;
					}
				}
			}
			.c-goods__item-desc {
				@media screen and ( max-width: 1199px ) {
					margin-top: 50px;
				}
			}
		}
		&-title {
			span {
				font-family: GothamPro;
				color: #101010;
				font-size: 13px;
				line-height: 18px;
				font-weight: 500;
			}
		}
		&-desc {
			max-width: 520px;
			margin-top: 72px;
			p {
				font-family: FuturaPT;
				font-size: 18px;
				line-height: 27px;
				color: #101010;
			}
			@media screen and ( max-width: 1199px ) {
				margin-top: 105px;
				max-width: 100%;
			}
		}
	}
	.c-goods__item-img-inner {
		height: 100%;
	}
	// .c-header {
	// 	@media screen and ( max-width: 580px ) {
	// 		display: none;
	// 	 }
	// }
	.video {
		height: 100%;
		min-height: 100%;
	}
}