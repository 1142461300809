.c-home {
	height: 100vh;
	min-width: 100vw;
	margin-bottom: 120px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media screen and ( max-width: 1199px ) {
		margin-bottom: 0;
	}
	@media screen and ( max-width: 580px ) {
		padding: 36px 24px;
	 }
	&__slider {
		display: flex;
		justify-content: center;
		width: 100%;
		z-index: 3;
		position: relative;
		@media screen and ( max-width: 1199px ) {
			height: 100%;
		}
		&-main {
			width: 600px;
			height: 600px;
			border-radius: 50%;
			border: 2px solid rgba($color: #ffffff, $alpha: 0.2);
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			@media screen and ( max-width: 1919px ) {
				height: 444px;
				width: 444px;
			 }
			@media screen and ( max-width: 1599px ) {
				height: 377px;
				width: 377px;
			 }
			@media screen and ( max-width: 1365px ) {
				height: 444px;
				width: 444px;
			 }
			@media screen and ( max-width: 1199px ) {
				border: none;
				height: 100%;
				width: 286px;
				margin-left: auto;
				margin-right: 60px;
				align-items: flex-start;
				margin-top: 0;
			}
			@media screen and ( max-width: 580px ) {
				margin-right: auto;
				margin-left: 0;
				width: 100%;
			 }
			.slick-arrow {
				position: absolute;
				bottom: 90px;
				cursor: pointer;
				z-index: 999999;
				@media screen and ( max-width: 580px ) {
					bottom: 36px;
				 }
			}
			.slick-prev {
				background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCA3MiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcyIDEwQzY4LjUzNjYgMTAgMjQuMjIzNiAxMCAyIDEwTTIgMTBMMTEgMU0yIDEwTDExIDE5IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
				background-repeat: no-repeat;
				font-size: 0;
				width: 72px;
				height: 20px;
				cursor: pointer;
				z-index: 999999;
				@media screen and ( max-width: 580px ) {
					left: 0;
				 }
			}
			.slick-next {
				background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCA3MiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcyIDEwQzY4LjUzNjYgMTAgMjQuMjIzNiAxMCAyIDEwTTIgMTBMMTEgMU0yIDEwTDExIDE5IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
				background-repeat: no-repeat;
				font-size: 0;
				width: 72px;
				height: 20px;
				transform: rotate(180deg);
				right: 0;
				@media screen and ( max-width: 580px ) {
					right: 0;
				 }
			}
			.slick-dots {
				list-style-type: none;
				li {
					height: 9px;
					width: 9px;
					border-radius: 50%;
					background-color: #fff;
					position: absolute;
					&:after {
						content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi41IDlDMTIuMzU3OSA5IDkgMTIuMzU3OSA5IDE2LjVDOSAyMC42NDIxIDEyLjM1NzkgMjQgMTYuNSAyNEMyMC42NDIxIDI0IDI0IDIwLjY0MjEgMjQgMTYuNUMyNCAxMi4zNTc5IDIwLjY0MjEgOSAxNi41IDlaTTMzIDE2LjVDMzMgMjUuNjEyNyAyNS42MTI3IDMzIDE2LjUgMzNDNy4zODczIDMzIDAgMjUuNjEyNyAwIDE2LjVDMCA3LjM4NzMgNy4zODczIDAgMTYuNSAwQzI1LjYxMjcgMCAzMyA3LjM4NzMgMzMgMTYuNVoiIGZpbGw9IndoaXRlIiBmaWxsLW9wYWNpdHk9IjAuMiIvPgo8L3N2Zz4K");
						position: absolute;
						top: -12px;
						left: -12px;
						bottom: 0;
						right: 0;
						display: inline-block;
						height: 33px;
						width: 33px;
						opacity: 0;
						transition: all 0.3s ease;
					}
					cursor: pointer;
					&:first-child {
						top: -5px;
						left: 50%;
						transform: translateX(-50%);
					}
					&:nth-child(2) {
						right: 33px;
						bottom: 146px;
						@media screen and ( max-width: 1919px ) {
							right: 5px;
						 }
						@media screen and ( max-width: 1599px ) {
							right: 5px;
							bottom: 120px;
						 }
						 @media screen and ( max-width: 1365px ) {
							right: 15px;
						 }
					}
					&:nth-child(3) {
						left: 33px;
						bottom: 146px;
						@media screen and ( max-width: 1919px ) {
							left: 5px;
						 }
						@media screen and ( max-width: 1599px ) {
							left: 5px;
							bottom: 120px;
						 }
						 @media screen and ( max-width: 1365px ) {
							left: 15px;
						 }
					}
					button {
						font-size: 0;
					}
					&.slick-active {
						&:after {
							opacity: 1;
						}
					}
				}
			}
		}
		&-item {
			height: 600px;
			width: 600px; 
			border-radius: 50%;
			@media screen and ( max-width: 1199px ) {
				margin-top: 180px;
			}
			@media screen and ( max-width: 580px ) {
				width: 100%;
			}
			h2 {
				font-family: GothamPro;
				font-size: 144px;
				line-height: 144px;
				text-transform: uppercase;
				color: #ffffff;
				font-weight: 700;
				margin: 0;
				text-align: center;
				margin-top: 164px;
				margin-left: 60px;
				@media screen and ( max-width: 1919px ) {
					margin-left: 60px;
					font-size: 106px;
				}
				@media screen and ( max-width: 1599px ) {
					font-size: 90px;
					margin-left: 40px;
				}
				@media screen and ( max-width: 1365px ) {
					font-size: 108px;
				 }
				@media screen and ( max-width: 1199px ) {
					font-size: 60px;
					text-align: right;
					margin: 0;
					line-height: 60px;
				 }
				 @media screen and ( max-width: 580px ) {
					text-align: left;

				 }

			}
			span {
				font-family: GothamPro;
				font-size: 12px;
				line-height: 18px;
				text-transform: uppercase;
				color: #ffffff;
				max-width: 360px;
				text-align: center;
				display: inline-block;
				text-align: center;
				margin-top: 46px;
				margin-left: 120px;
				@media screen and ( max-width: 1919px ) {
					max-width: 290px;
					margin-left: 70px;
				}
				@media screen and ( max-width: 1599px ) {
					max-width: 240px;
				 }
				 @media screen and ( max-width: 1365px ) {
					margin-left: 100px;
				 }
				 @media screen and ( max-width: 1199px ) {
					margin-top: 15px;
					max-width: 256px;
					text-align: right;
				 }
				 @media screen and ( max-width: 580px ) {
					margin-left: 0;
					text-align: left;
					max-width: 100%;
				 }
			}
		}
	}

	&__next-section, .next-section {
		position: absolute;
		right: 47px;
		bottom: 27px;
		display: flex;
		@media screen and ( max-width: 1199px ) {
			display: none;
		}
		button {
			display: flex;
			align-items: center;
			span {
				font-family: GothamPro;
				font-size: 12px;
				line-height: 18px;
				text-transform: uppercase;
				color: #ffffff;
			}
			.icon {
				width: 74px;
				margin-left: 24px;
				font-size: 60px;
				animation: slidegorizont 2s ease 0.1s infinite;
				color: #ffffff;
			}
		}
		&.black {
			span {
				color: #101010;
			}
			.icon {
				width: 74px;
				margin-left: 24px;
				font-size: 60px;
				animation: slidegorizont 2s ease 0.1s infinite;
				color: #101010;
			}
		}
	}
	&__text {
			max-width: 420px;
			// margin-left: 90px;
			// margin-top: -53px;
			z-index: 3;
			// position: relative;
			position: absolute;
			bottom: 120px;
			left: 90px;
			@media screen and ( max-width: 1919px ) {
			}
			@media screen and ( max-width: 1599px ) {
				max-width: 240px;
				left: 60px;
				bottom: 90px;
			 }
			 @media screen and ( max-width: 1199px ) {
				position: absolute;
				bottom: 90px;
				left: 90px;
				margin: 0;
			}
			@media screen and ( max-width: 580px ) {
				background-color: #000;
				color: #fff;
				left: 0;
				bottom: 0;
				right: 0;
				max-width: 100%;
				width: 100%;
				padding: 48px 24px;
			 }
		p {
			font-family: FuturaPT;
			font-size: 18px;
			line-height: 27px;
			color: white;
			font-weight: 500;
		}
		&--lg {
			@media screen and ( max-width: 580px ) {
				display: none;
			 }
		}
		&--xx {
			display: none;
			@media screen and ( max-width: 580px ) {
				display: block;
				position: relative;
			 }
		}
	}
}

@keyframes slidegorizont {
  from {
    transform: translateX(0px);
  }
	
	50% {
		transform: translateX(5px);
	}

  to {
		transform: translateX(0px);
 	}
}


