img {
	border: 0;
}


/* Tiny Circleslider */

#rotatescroll {
	height: 600px;
	position: relative;
	width: 600px;
}

#rotatescroll .viewport {
	height: 600px;
	position: relative;
	margin: 0 auto;
	overflow: hidden;
	width: 600px;
	pointer-events: none
}

#rotatescroll .overview {
	position: absolute;
	width: 798px;
	list-style: none;
	margin: 0;
	padding: 0;
	left: 0;
	top: 0;
}

#rotatescroll .overview li {
	height: 600px;
	width: 600px;
	float: left;
	position: relative;
}

#rotatescroll .overlay {
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 0;
	height: 600px;
	width: 600px;
}

#rotatescroll .thumb {
	touch-action: none;
	-ms-touch-action: none;
	position: absolute;
	top: -3px;
	cursor: pointer;
	left: 137px;
	width: 33px;
	z-index: 200;
	height: 33px;
	background-image: url(../images/slider-thumb.svg);
}

#rotatescroll .dot {
	height: 9px;
	width: 9px;
	border-radius: 50%;
	background-color: #fff;
	position: absolute;
	left: 155px;
	top: 3px;
	z-index: 100;
	cursor: pointer;
}

#rotatescroll .dot span {
	display: none;
}