@mixin font-face ($fontname, $fontfile, $fontweight: normal, $fontstyle: normal) {
	@font-face {
		font-family: $fontname;
		src: url('../fonts/' + $fontfile + '.eot');
		src: url('../fonts/' + $fontfile + '.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/' + $fontfile + '.woff') format('woff'),
			 url('../fonts/' + $fontfile + '.ttf') format('truetype'),
			 url('../fonts/' + $fontfile + '.svg##{fontname}') format('svg');
		font-weight: $fontweight;
		font-style: $fontstyle;
	}
};

@include font-face('GothamPro', 'gothampro/GothamPro', 400, normal);
@include font-face('GothamPro', 'gothampro/GothamPro-Medium', 500, normal);
@include font-face('GothamPro', 'gothampro/GothamPro-Bold', 700, normal);
@include font-face('GothamPro', 'gothampro/GothamPro-Black', 900, normal);

@include font-face('FuturaPT', 'futura/FuturaPT-Book', normal);
@include font-face('FuturaPT', 'futura/FuturaPT-Bold', 700, normal);