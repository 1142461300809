.c-header{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		clip: rect(0, auto, auto, 0);
		width: 100%;
		transition: background-color 0.3s ease;
		&.white-fixed {
			background-color: #fff;
			left: 0;
			right: 0;
			padding-left: 48px;
			padding-right: 48px;
			color: #101010;
			clip-path: none;
			position: relative;
			height: auto;
			transition: background-color 0.3s ease;
			.c-header__inner {
				left: 0;
				right: 0;
				top: 0;
				padding-left: 48px;
				padding-right: 48px;
				height: 81px;
				display: flex;
				align-items: center;
				background-color: #fff;
				transition: background-color 0.3s ease;
			}
		}

	&__inner {
		justify-content: space-between;
		display: flex;
		position: fixed;
		align-items: center;
		top: 22px;
		left: 48px;
		right: 48px;
		height: 60px;
		z-index: 99998;
		transition: background-color 0.3s ease;
		@media screen and ( max-width: 580px ) {
			left: 24px;
			top: 24px;
			right: 24px;
			position: fixed;
		 }
	}
	&__left {
		align-items: center;
		display: flex;
		margin-top: -3px;
		position: relative;
		&:hover {
			.c-header__mmenu {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&__right {
		display: flex;
		@media screen and ( max-width: 580px ) {
			display: none;
		 }
	}
	&__menu {
		&-btn {
			position: relative;
			display: flex;
			height: 9px;
			width: 20px;
			&:after, &:before {
				content: '';
				display: inline-block;
				position: absolute;
				height: 2px;
				width: 20px;
				content: '';
				background-color: #fff;
			}
			&:before {
				top: 0;
			}
			&:after {
				bottom: 0;
			}
		}
	}
	&__logo {
		margin-left: 24px;
		a {
			text-transform: uppercase;
			font-family: GothamPro;
			line-height: 20px;
			font-weight: 900;
			font-size: 21px;
			display: flex;
			color: #fff;
		}
	}
	&__contact {
		&-btn {
			background-color: transparent;
			text-transform: uppercase;
			font-family: GothamPro;
			padding: 14px 24px;
			border-radius: 9px;
			text-align: center;
			font-weight: 700;
			font-size: 12px;
			border: 1px solid rgba(255, 255, 255, 0.8);
			color: #fff;
			display: flex;
			transition: all 0.3s ease;
			&:hover {
				background: #fff;
				border: 1px solid #fff !important;
				color: #101010 !important;
			}
		}
	}
	
	&.black-header {
		.c-header__logo {
			a {
				color: #101010;
			}
		}
		.c-header__contact-btn {
			border: 1px solid rgba(16, 16, 16, 0.28);
			color: #101010;
			&:hover {
				background: #101010;
				border: 1px solid #fff !important;
				color: #fff !important;
			}
		}
		.c-header__menu-btn {
			&:after, &:before {
				content: '';
				background-color: #101010;
			}
		}
	}
	
	&__mmenu {
		position: absolute;
		border-radius: 9px;
		background: rgba($color: #101010, $alpha: 0.92);
		top: 40px;
		left: -30px;
		padding: 36px;
		min-width: 252px;
		min-height: 337px;
		transition: all 0.3s ease;
		opacity: 0;
		visibility: hidden;
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 50px;
			left: 0px;
			top: -50px;
		}
		@media screen and ( max-width: 580px  ) {
			left: 0;
		 }
		ul {
			margin: 0;
			padding: 0;
			li {
				a {
					font-family: GothamPro;
					font-size: 12px;
					line-height: 18px;
					text-transform: uppercase;
					color: #888888;
					font-weight: 700;
					display: block;
					transition: all 0.3s ease;
					&:hover {
						color: #fff;
					}
				}
				&:not(:last-child) {
					margin-bottom: 24px;
				}
				&:last-child {
					margin-bottom: 34px;
				}
			}
		}
		&-btn {
			button {
				padding: 14px 23px;
				transition: all 0.3s ease;
				&:hover {
					background: #fff;
					border: 1px solid #fff !important;
					color: #101010 !important;
				}
			}
		}
		&.active {
			opacity: 1;
			visibility: visible;
		}
	}
}

.c-header__contact-btn--mmenu {
	color: #fff !important;
	border: 1px solid rgba(255, 255, 255, 0.8) !important;
}
.white-fixed {
	.js-close-modal {
		path {
			stroke: #101010;
		}
	}
	.c-header__mmenu {
		@media screen and ( max-width: 1199px  ) {
			top: 64px;
		 }
		@media screen and ( max-width: 580px  ) {
			left: -32px;
		 }
	}
}