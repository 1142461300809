.c-fish {
	min-width: 2145px;
	width: auto;
	background-color: #fff;
	height: 100%;
	position: relative;
	@media screen and ( max-width: 1199px ) {
		min-width: 100%;
		width: 100%;
		padding: 120px 90px;
	}
	@media screen and ( max-width: 580px ) {
		padding: 24px;
		padding-bottom: 60px;
	 }
	&__inner {
		height: 100%;
		display: flex;
		@media screen and ( max-width: 1199px ) {
			flex-direction: column;
		}
		.c-section__desc {
			position: absolute;
			bottom: 120px;
			left: 60px;
			margin: 0;
			@media screen and ( max-width: 1199px ) {
				height: auto;
				margin-left: 0;
				margin-bottom: 0;
				position: relative;
				left: auto;
				bottom: auto;
			}
			@media screen and ( max-width: 580px ) {
				margin-top: -50px;
			 }
			.c-section__title {
				bottom: -10px;
    		right: -103px;
			}
			.c-section__title h5 {
				white-space: nowrap;
			}
		}
	}
	&__tabs {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		justify-content: center;
		@media screen and ( max-width: 1199px ) {
			flex-direction: column;
			align-items: flex-start;
		}
		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			flex-direction: column;
			@media screen and ( max-width: 1199px ) {
				flex-wrap: wrap;
				margin-top: 90px;
				padding-left: 35px;
			}
			li {
				font-family: FuturaPT;
				font-size: 18px;
				line-height: 30px;
				color: #888;
				position: relative;
				cursor: pointer;
				@media screen and ( max-width: 1199px ) {
					font-size: 16px;
					line-height: 24px;
					margin-right: 60px;
				}
				&:after {
					content: '';
					position: absolute;
					width: 12px;
					height: 2px;
					display: inline-block;
					background-color: #101010;
					left: -30px;
					top: 50%;
					transform: translateY(-50%);
					opacity: 0;
					visibility: hidden;
					transition: all 0.3s ease;
				}
				&.active {
					color: #101010;
					&:after {
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
		.tab_container {
			width: 765px;
			margin-left: 94px;
			margin-top: 87px;
			z-index: 9999999;
			@media screen and ( max-width: 1199px ) {
				width: 100%;
				margin-left: 0;
				margin-top: 150px;
			}
			@media screen and ( max-width: 580px ) {
				margin-top: 48px;
			 }
		}
		.tabs {
			width: 240px;
			margin-left: 267px;
			@media screen and ( max-width: 1199px ) {
				margin-left: 0;
				width: auto;
				height: 240px;
			}
			@media screen and ( max-width: 580px ) {
				margin-top: 48px;
			 }
		}
	}
	&__img {
		width: 765px;
		height: 264px;
		@media screen and ( max-width: 1199px ) {
			width: 100%;
			height: auto;
		}
		&-inner {
			height: 100%;
		}
	}
	&__name {
		margin-top: 90px;
		@media screen and ( max-width: 1199px ) {
			margin-top: 50px;
		}
		span {
			font-family: FuturaPT;
			font-size: 21px;
			line-height: 32px;
			color: #101010;
			@media screen and ( max-width: 1199px ) {
				font-size: 18px;
			}
		}
	}
	&__desc {
		margin-top: 18px;
		p {
			font-family: FuturaPT;
			font-size: 18px;
			line-height: 27px;
			color: #101010;
		}
	}
	// .c-header {
	// 	@media screen and ( max-width: 580px ) {
	// 		display: none;
	// 	 }
	// }
}


//Tabs
